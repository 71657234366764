<template>
  <div
    class="wrapper"
    ref="wrapper"
  >
    <div class="d-flex flex-row">
      <v-menu
        :attach="typeof attach !== 'undefined' ? attach : $refs.wrapper"
        :bottom="bottom"
        content-class="menu-width"
        :close-on-content-click="false"
        eager
        :left="left"
        max-height="500"
        offset-y
        :origin="computeOrigin"
        :right="right"
        :top="!bottom && typeof top === 'boolean' ? top : isOutOfView"
        transition="scale-transition"
        v-model="isOpen"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            small
          >
            <v-icon class="mr-2">{{$icon('i.Tokens')}}</v-icon>
            <span>{{$t('t.InsertToken')}}</span>
          </v-btn>
        </template>
        <v-card>
          <v-text-field
            flat
            clearable
            dense
            autofocus
            hide-details
            :label="$t('t.Filter')"
            solo
            v-model="tokenFilter"
            :prepend-inner-icon="$icon('i.Search')"
          />
          <v-divider></v-divider>
          <v-list
            dense
            v-if="_tokens"
            class="item-list-container"
          >
            <v-list-group
              v-for="(_, idx1) in _tokens"
              :key="idx1"
              no-action
            >
              <template v-slot:activator>
                <!-- <v-list-item-content>
                  <v-list-item-title v-text="_.category"></v-list-item-title>
                </v-list-item-content> -->
                <v-list-item-content>
                  <span>{{_.category}}</span>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(i, idx2) in _.items"
                :key="idx2"
                @click="selectToken(i)"
              >
                <v-list-item-content>
                  <span>{{i.displayName}}</span>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </div>

</template>

<script>

export default {
  components: {
  },
  computed: {
    _tokens () {
      const items = this.tokens.filter(_ => {
        return this.tokenFilter
          ? _.category.toLowerCase().includes(this.tokenFilter) || _.displayName.toLowerCase().includes(this.tokenFilter)
          : true
      })

      const groups = this.lodash(items)

        .groupBy(_ => _.category)
        .map(function (items, c) {
          return {
            category: c,
            items: items
          }
        }).value()

      return groups
    },
    computeOrigin () {
      return (this.left ? 'right' : 'left') + ' top'
    }
  },
  data () {
    return {
      isOpen: false,
      isOutOfView: false,
      observer: new ResizeObserver(this.eventHandler),
      tokenFilter: null
    }
  },
  methods: {
    eventHandler (entries) {
      const rect = entries[entries.length - 1].contentRect
      this.isOutOfView = (document.body.getBoundingClientRect().bottom - document.querySelector('footer').offsetHeight) < (this.$refs.wrapper.getBoundingClientRect().bottom + rect.bottom)
      setTimeout(this.$triggerResize, 200)
    },
    selectToken (token) {
      this.$emit('token-selected', token)
      this.isOpen = false
    }
  },
  async mounted () {
    const e = await this.$waitFor(() => this.$el.querySelector('div.v-menu__content'))
    if (e) {
      this.observer.observe(e)
    }
  },
  beforeDestroy () {
    this.observer.disconnect()
  },
  props: {
    attach: {
      type: [String, Boolean, Object, HTMLElement],
      default: undefined
    },
    bottom: Boolean,
    left: Boolean,
    right: Boolean,
    tokens: Array,
    top: {
      type: Boolean,
      default: undefined
    }
  }
}
</script>

<style lang="stylus">
.item-list-container
  overflow-y auto
  max-height 461px

.menu-width
  width max-content
</style>
